import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { store, i18n, vuetify } from "ekx-vue-base"
// import 'simplebar/dist/simplebar.min.css';
import "./modules/core/utils/EkxAuth"
import './assets/scss/_styles.scss';
import './assets/animate.css';
import "./ComponentLoader";

import "./modules/core/utils/ErrorHandler"
import "brace/ext/language_tools";
import "brace/mode/html";
import "brace/mode/javascript";
import "brace/mode/less";
import "brace/theme/chrome";
import "brace/snippets/javascript";
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = true;
Vue.config.silent = true;

export default new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');
console.log('env', process.env);
